import { CONFIG } from "./config";
import { normalizeUrl } from "./../utils/normalizeUrl";

export const ENDPOINTS = {
  SHIFT: {
    GET: normalizeUrl(`${CONFIG.ONDEMAND_API}/shifts/v2`),
    CREATE: normalizeUrl(`${CONFIG.ONDEMAND_API}/shifts`),
  },
  LOCATION: {
    GET: normalizeUrl(`${CONFIG.ONDEMAND_API}/locations`),
    CONTACTS: normalizeUrl(
      `${CONFIG.BASE_URL}/v2/api/placements/locations/:locationId/contacts`
    ),
    V2: {
      BASE: normalizeUrl(`${CONFIG.ONDEMAND_API}/locations/v2`),
    },
    CHILD_LOCATION: normalizeUrl(
      `${CONFIG.ONDEMAND_API}/locations/`
    ),
  },
  CONTACT: {
    BASE: normalizeUrl(`${CONFIG.ONDEMAND_API}/company-contacts`),
    ID: normalizeUrl(`${CONFIG.ONDEMAND_API}/company-contacts/:contactId`),
    RESTORE: normalizeUrl(
      `${CONFIG.ONDEMAND_API}/company-contacts/:contactId/restore`
    ),
    NOTE: {
      BASE: normalizeUrl(
        `${CONFIG.ONDEMAND_API}/company-contacts/:contactId/notes`
      ),
      ID: normalizeUrl(
        `${CONFIG.ONDEMAND_API}/company-contacts/:contactId/notes/:noteId`
      ),
    },
    ASSIGN: normalizeUrl(
      `${CONFIG.ONDEMAND_API}/company-contacts/:contactId/assign/:parentLocationId`
    ),
    UNASSIGN: normalizeUrl(
      `${CONFIG.ONDEMAND_API}/company-contacts/:contactId/unassign/:parentLocationId`
    ),
    UNASSIGN_ALL: normalizeUrl(
      `${CONFIG.ONDEMAND_API}/company-contacts/:contactId/unassign/all`
    ),
    AVATAR: normalizeUrl(
      `${CONFIG.ONDEMAND_API}/company-contacts/:contactId/avatar`
    ),
  },
  POSITION: {
    GET: normalizeUrl(`${CONFIG.ONDEMAND_API}/jobs`),
  },
  USER: {
    DETAILS: "/client-portal/user",
    DETAILS_V2: "/client-portal/v2/user",
  },
  COMPANY: {
    PUBLIC: {
      GET: normalizeUrl(`${CONFIG.BASE_URL}/v2/api/public/company/:companyId`),
    },
    COMPANY_LOCATION_DETAILS: {
      GET: "/v2/api/company/locations/:companyLocationId",
      UPDATE: normalizeUrl(
        `${CONFIG.ONDEMAND_API}/locations/:companyLocationId`
      ),
      CREATE: normalizeUrl(`${CONFIG.ONDEMAND_API}/locations/`),
    },
    LIST: {
      GET: "/v2/api/companies",
    },
    LOCATIONS: {
      GET: "/v2/api/company/locations/list",
      SYNC: "/v2/api/company/locations/sync",
      ACTION: "/v2/api/company/locations/:action", // archive or unarchive
      STATUS:normalizeUrl(
        `${CONFIG.ONDEMAND_API}/locations/:companyLocationId/status`)
    },
    KIOSKS: {
      BASE: "/v2/api/kiosks",
      GET: "/v2/api/kiosks",
      PUT: "/v2/api/kiosks",
      ENABLE: "/v2/api/kiosks/enable",
      GENERATE_PIN: "/v2/api/kiosks/generate-pin",
    },
    NOTE_TYPES: {
      GET: "/v2/api/company/note/types/lists",
      POST: "/v2/api/company/note/types",
      PUT_ORDER: "/v2/api/company/note/types",
      DELETE: "/v2/api/company/note/types/:noteTypeId",
    },
    NOTES: {
      BASE: normalizeUrl(`${CONFIG.ONDEMAND_API}/locations/:companyLocationId/notes`),
      CREATE: normalizeUrl(`${CONFIG.ONDEMAND_API}/locations/:companyLocationId/notes`),
      ID: normalizeUrl(
        `${CONFIG.ONDEMAND_API}/locations/:companyLocationId/notes/:noteId`
      ),
    },
    AVATAR: normalizeUrl(
      `${CONFIG.ONDEMAND_API}/locations/:companyId/logo`
    ),
    STATUS: {
      BASE: normalizeUrl(
        `${CONFIG.ONDEMAND_API}/locations/status`
      ),
      ID: normalizeUrl(
        `${CONFIG.ONDEMAND_API}/locations/status/:statusId`
      ),
      REORDER: normalizeUrl(
        `${CONFIG.ONDEMAND_API}/locations/status/reorder`
      ),
      SET_DEFAULT: normalizeUrl(
        `${CONFIG.ONDEMAND_API}/locations/status/default`
      ),
    },
    UPDATE_STATUS: normalizeUrl(
      `${CONFIG.ONDEMAND_API}/locations/:locationId/status`
    ),
    POSITIONS: {
      POST: "/v2/api/company/position",
      PUT: "/v2/api/company/position",
      DELETE: "/v2/api/company/position",
    },
  },
  MESSAGING: {
    SMS: normalizeUrl(`${CONFIG.BASE_URL}/v2/api/messages/sms`),
    IN_APP: normalizeUrl(`${CONFIG.BASE_URL}/v2/api/messages`),
    CRISP: {
      BASE: "/client-portal/crisp",
      CONVERSATION: "/client-portal/crisp/conversation",
      PLUGINS: "/client-portal/crisp/plugins",
    },
    PUSH_NOTIFICATION: normalizeUrl(
      `${CONFIG.BASE_URL}/v2/api/push-notification/:companyId`
    ),
    SEND_NOTIFICATION: normalizeUrl(`${CONFIG.BASE_URL}/v2/api/notify-now`),
    ARCHIVED_NOTIFICATION: normalizeUrl(
      `${CONFIG.BASE_URL}/v2/api/archived-now`
    ),
  },
  AUTH: {
    LOGIN_REQUEST: "/client-portal/login-request",
    LOGIN_REQUEST_V2: "/client-portal/v2/login-request",
    VERIFY_LOGIN_TOKEN: "/client-portal/verify-login",
    VERIFY_LOGIN_TOKEN_V2: "/client-portal/v2/verify-login",
  },
  SETTINGS: "/client-portal/:company_id/settings",
  SETTINGS_PUBLIC: "/client-portal/:company_id/settings/public",
  TIMESHEETS: {
    BASE: "/client-portal/timesheets/v2",
    MARK: "/client-portal/timesheets/v2/mark",
    INVOICE: "/client-portal/timesheets/v2/invoice",
    RESTORE: "/client-portal/timesheets/v2/restore",
    SUBMIT: "/client-portal/timesheets/submit",
    COUNT: "/client-portal/timesheets/count",
    LOGS: "/client-portal/timesheets/logs",
    EXPORT: {
      "PAYCHEX-CSV": "/client-portal/timesheets/export/paychex-csv",
      CSV: "/client-portal/timesheets/export/csv",
      "PDF-CONDENSED": "/client-portal/timesheets/export/pdf-condensed",
      CUSTOM: "/client-portal/timesheets/export/custom",
    },
    USERS: "/client-portal/timesheets/users",
    CREATE: "/v2/app/timerecord/timesheet",
    PAYROLL: "/client-portal/timesheets/payroll",
  },
  TIMECARDS: {
    BASE: "/client-portal/timecards",
    CREATE: "/v2/app/timerecord/timecard",
    DELETE: "/v2/app/timerecord/timecard",
  },
  WHOSWORKING: {
    BASE: "/client-portal/whos-working",
  },
  CANDIDATES: {
    BASE: normalizeUrl(`${CONFIG.CANDIDATES_API}/:companyId/candidates`),
    CANDIDATE_NOTIFICATION: normalizeUrl(
      `${CONFIG.BASE_URL}v2/api/candidates/push_notification`
    ),
    CANDIDATE_NOTIFICATION_SCHEDULED: normalizeUrl(
      `${CONFIG.BASE_URL}v2/api/candidates/push_notification_scheduled`
    ),
    CANDIDATE_DUPLICATE: normalizeUrl(
      `${CONFIG.BASE_URL}v2/api/check_duplicate`
    ),
    RESUME: normalizeUrl(
      `${CONFIG.CANDIDATES_API}/:companyId/candidates/resume`
    ),
    UPDATE_STATUS: normalizeUrl(
      `${CONFIG.CANDIDATES_API}/:companyId/candidates/:candidateId/status`
    ),
    FILES: {
      BASE: normalizeUrl(
        `${CONFIG.CANDIDATES_API}/:companyId/candidates/:candidateId/files`
      ),
      ARCHIVE: normalizeUrl(
        `${CONFIG.CANDIDATES_API}/:companyId/candidates/:candidateId/files/:fileId/archive`
      ),
      RESTORE: normalizeUrl(
        `${CONFIG.CANDIDATES_API}/:companyId/candidates/:candidateId/files/:fileId/restore`
      ),
      ID: normalizeUrl(
        `${CONFIG.CANDIDATES_API}/:companyId/candidates/:candidateId/files/:fileId`
      ),
    },
    AVATAR: normalizeUrl(
      `${CONFIG.CANDIDATES_API}/:companyId/candidates/avatar`
    ),
    ID: normalizeUrl(
      `${CONFIG.CANDIDATES_API}/:companyId/candidates/:candidateId`
    ),
    ARCHIVE_CANDIDATE: normalizeUrl(
      `${CONFIG.CANDIDATES_API}/:companyId/candidates/:candidateId/archive`
    ),
    SETTINGS: {
      PROFILE_SECTIONS: normalizeUrl(
        `${CONFIG.CANDIDATES_API}/:companyId/candidates/settings/profile-sections`
      ),
      CUSTOM_FIELDS: normalizeUrl(
        `${CONFIG.CANDIDATES_API}/:companyId/candidates/settings/custom-fields`
      ),
    },
    STATUS: {
      BASE: normalizeUrl(
        `${CONFIG.CANDIDATES_API}/:companyId/candidates/status`
      ),
      ID: normalizeUrl(
        `${CONFIG.CANDIDATES_API}/:companyId/candidates/status/:statusId`
      ),
      REORDER: normalizeUrl(
        `${CONFIG.CANDIDATES_API}/:companyId/candidates/status/reorder`
      ),
      SET_DEFAULT: normalizeUrl(
        `${CONFIG.CANDIDATES_API}/:companyId/candidates/status/set-default`
      ),
    },
    APP_STATUS: {
      BASE: "/candidate/status/:candidateId",
      SYNC_CACHE: "/candidate/sync-cache/:candidateId",
      GET: {
        APPROVED: normalizeUrl(
          `${CONFIG.ONDEMAND_API}/users/approve/:candidateId`
        ),
        UNAPPROVED: normalizeUrl(
          `${CONFIG.ONDEMAND_API}/users/unapprove/:candidateId`
        ),
      },
    },
    NOTES: {
      BASE: normalizeUrl(
        `${CONFIG.CANDIDATES_API}/:companyId/candidates/:candidateId/notes`
      ),
      ID: normalizeUrl(
        `${CONFIG.CANDIDATES_API}/:companyId/candidates/:candidateId/notes/:noteId`
      ),
    },
    PREFERENCE: {
      BASE: normalizeUrl(
        `${CONFIG.CANDIDATES_API}/:companyId/candidates/:candidateId/preference`
      ),
      ID: normalizeUrl(
        `${CONFIG.CANDIDATES_API}/:companyId/candidates/:candidateId/preference/:preferenceId`
      ),
    },
    AVAILABILITY: {
      BASE: normalizeUrl(
        `${CONFIG.CANDIDATES_API}/:companyId/candidates/:candidateId/availability`
      ),
      ID: normalizeUrl(
        `${CONFIG.CANDIDATES_API}/:companyId/candidates/:candidateId/availability/:availabilityId`
      ),
    },
    UNAVAILABILITY: {
      BASE: normalizeUrl(
        `${CONFIG.CANDIDATES_API}/:companyId/candidates/:candidateId/unavailability`
      ),
      ID: normalizeUrl(
        `${CONFIG.CANDIDATES_API}/:companyId/candidates/:candidateId/unavailability/:unavailabilityId`
      ),
    },
    EXCLUSION: {
      BASE: normalizeUrl(
        `${CONFIG.CANDIDATES_API}/:companyId/candidates/:candidateId/exclusion`
      ),
      ID: normalizeUrl(
        `${CONFIG.CANDIDATES_API}/:companyId/candidates/:candidateId/exclusion/:exclusionId`
      ),
    },
    TOGGLE_SHIFTS: "/member/shifts-toggle",
    EXPORT_ASSIGNED_LOCATION: normalizeUrl(
      `${CONFIG.CANDIDATES_API}/:companyId/candidates/location-export`
    ),
  },
  USERS: {
    USER: {
      BASE: normalizeUrl(`${CONFIG.POSTGRE_API}/postgre/users/:companyId`),
      UPDATE_USERS: normalizeUrl(`${CONFIG.POSTGRE_API}/postgre/users`),
    },
    REGION: {
      ALL_REGION: normalizeUrl(`${CONFIG.POSTGRE_API}/postgre/regions`),
    },
    BRANCHES: {
      BASE: normalizeUrl(`${CONFIG.POSTGRE_API}/postgre/branches/:companyId`),
      ALL_BRANCHES: normalizeUrl(`${CONFIG.POSTGRE_API}/postgre/branches`),
      GET_BRANCHES: normalizeUrl(
        `${CONFIG.POSTGRE_API}/postgre/getbranches/:id`
      ),
      UPDATE_BRANCHES: normalizeUrl(`${CONFIG.POSTGRE_API}/postgre/branches`),
    },
    ROLES: {
      BASE: normalizeUrl(`${CONFIG.POSTGRE_API}/postgre/roles/:companyId`),
      ALL_ROLES: normalizeUrl(`${CONFIG.POSTGRE_API}/postgre/roles`),
      GET_ROLES: normalizeUrl(`${CONFIG.POSTGRE_API}/postgre/getroles/:id`),
      ROLES_PERMISSION: normalizeUrl(
        `${CONFIG.POSTGRE_API}/postgre/roles_permission/roles`
      ),
      ROLES_PERMISSION_ENABLED: normalizeUrl(
        `${CONFIG.POSTGRE_API}/postgre/roles_permission/roles`
      ),
      SUB_ROLES_PERMISSION: normalizeUrl(
        `${CONFIG.POSTGRE_API}/postgre/roles/sub_roles_permission/:companyid`
      ),
      SUB_ROLES_PERMISSION_ENABLED: normalizeUrl(
        `${CONFIG.POSTGRE_API}/postgre/subroles_permission/roles`
      ),
      UPDATE_ROLES: normalizeUrl(`${CONFIG.POSTGRE_API}/postgre/roles`),
    },
    TIMEZONE: {
      ALL_TIMEZONE: normalizeUrl(`${CONFIG.POSTGRE_API}/postgre/timezone`),
    },
  },
  JOB: {
    SETTINGS: "/job-settings/:companyId",
    APPLYNOW: {
      FILTERS: "/client-portal/jobs/filters",
      UPDATE_FILTER_OPTION: "/client-portal/jobs/filter/option",
    }
  },
  TABLE_CONFIG: {
    BASE: "/:company_id/table-config",
    ID: "/:company_id/table-config/:id",
    ACTIVE: "/:company_id/table-config/active/:id",
  },
  PLACEMENTS: {
    BASE: "/v2/api/placements",
    POSITIONS: normalizeUrl(`${CONFIG.ONDEMAND_API}/jobs`),
    INDUSTRIES: normalizeUrl(`${CONFIG.ONDEMAND_API}/categories`),
    SPECIALTIES: normalizeUrl(`${CONFIG.ONDEMAND_API}/specialties`),
    JOB_TYPES: normalizeUrl(`${CONFIG.ONDEMAND_API}/placements/job-types`),
    COMPANIES: "/v2/api/placements/companies",
    ACTIONS: "/v2/api/placements/action/:action",
    CANDIDATES: "/v2/api/placements/candidates",
    SEARCH_CANDIDATES: "/v2/api/placements/search-candidates",
    CANDIDATE_ID: "/v2/api/placements/candidates/:candidateId",
    TEMPLATES: "/v2/api/placements/templates",
    DOCUMENTS: "/v2/api/placements/documents",
    REPORTS: "/v2/api/placements/reports",
    ATS: "/v2/api/placements/ats",
  },
  ORDERS: {
    BASE: "/v2/api/orders",
    ACTIONS: "/v2/api/orders/action/:action",
  },
  SPECIALTIES: {
    BASE: "/industry/job/specialties",
  },
  GROUPS: {
    COMPANY: "/v2/api/groups/_company/:companyId",
    BASE: "/client-portal/:companyId/groups",
    ADD_USERS: "/client-portal/:companyId/groups/:groupId/add-users",
    REMOVE_USERS: "/client-portal/:companyId/groups/:groupId/remove-users",
    REORDER: "/client-portal/:companyId/groups/reorder",
    DELETE: "/client-portal/:companyId/groups/:groupId",
    SET_DEFAULT: "/client-portal/:companyId/groups/:groupId/set-default",
  },
  NOTIFICATION_SETTINGS: {
    BASE: "/v2/api/notification-settings",
    GET_SETTINGS:
      "/v2/api/notification-settings/:company_id/:template_type/:notify_type",
    SET_SETTINGS:
      "/v2/api/notification-settings/setnotification/:company_id/:_id",
    UPDATE_SETTINGS: "/v2/api/notification-settings/:company_id/:_id",
    CREATE_SETTINGS: "/v2/api/notification-settings/create",
    RESTORE_SETTINGS:
      "/v2/api/notification-settings/restore/:company_id/:notification_template",
    UPDATEPACKET:
    normalizeUrl(`${CONFIG.ONBOARDING_API}/v2/api/updateTimezone`),
  },
  DOCUMENT_TYPE: "/onboarding/doc-type/get",
  CUSTOM_REPORTS: "/client-portal/custom-reports",
  TEMPWORKS_BRANCH: {
    GET_SETTINGS: normalizeUrl(
      `${CONFIG.TEMPWORKS_API}/temp-works/apply-now-settings`
    ),
    UPDATE_TEMPWORKS: normalizeUrl(
      `${CONFIG.TEMPWORKS_API}/candidate/ats/update`
    ),
  },
  ONBOARDING: {
    PACKET_LIST: "/v2/api/onboarding/packets/list",
    ASSIGNED_LIST: normalizeUrl(`${CONFIG.BASE_URL}/onboarding/user/status/pagination`),
    SEARCH_CANDIDATE: "/v2/api/members/search",
    FORMS: "/v2/api/onboarding/company/forms",
    CANDIDATE_PACKETS: "/v2/api/onboarding/assign/packets/:user_id/:packet_id",
    CHANGE_PACKET_STATUS: "/onboarding/change/packet/status",
    CHANGE_PACKETS_STATUS: "/onboarding/change/packets/status",
    ASSIGN: {
      PACKET: "/onboarding/packets/assign",
      FORM: "/v2/api/onboarding/individual/assign/form",
      CHECK: "/onboarding/assigned/packet/checker"
    },
    DOCUMENT_TYPES: "/onboarding/doc-type",
    CLIENT_PORTAL: {
      BASE: "/client-portal/onboarding",
      PACKETS: "/client-portal/onboarding/packets",
      ATS_DOC_TYPES: "/client-portal/onboarding/ats-doc-types",
      DOC_TYPES: "/client-portal/onboarding/doc-types",
      GDPR: "/client-portal/onboarding/gdpr",
      ADMIN_USERS: "/client-portal/admin/users",
      ADMIN_ROLES: "/client-portal/admin/roles",
      PERMISSION_DOC_TYPES: "/client-portal/onboarding/permission-doc-types",
    }
  },
};
