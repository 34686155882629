export const ROUTE = {
  TIMESHEETS: {
    BASE: "/timesheets",
  },
  WHOSWORKING: {
    BASE: "/whos-working",
  },
  SETTINGS: {
    ADMIN: {
      BASE: "/admin-settings",
      DEFAULT: "/admin-settings/client-portal",
      CLIENT_PORTAL: "/admin-settings/client-portal",
      NOTIFICATIONS: "/admin-settings/notifications",
      SHIFT: "/admin-settings/shift",
      SCHEDULING_TIMESHEETS: "/admin-settings/scheduling-timesheets",
      CANDIDATES: "/admin-settings/candidates",
      USERS: "/admin-settings/users",
      ROLES: "/admin-settings/roles",
      BRANCHES: "/admin-settings/roles",
      MOBILE_APP: {
        BASE: "/admin-settings/mobile-app/",
        NEW_USER_JOURNEY: {
          BASE: "/admin-settings/mobile-app/new-user-journey",
          LIST: "/admin-settings/mobile-app/new-user-journey/list",
          LANDING_PAGE:
            "/admin-settings/mobile-app/new-user-journey/landing-page",
          KEYWORDS: "/admin-settings/mobile-app/new-user-journey/keywords",
          INTERVIEW: "/admin-settings/mobile-app/new-user-journey/interview",
          CTA: "/admin-settings/mobile-app/new-user-journey/custom-cta",
        },
        MAINTENANCE_MODE: {
          BASE: "/admin-settings/mobile-app/maintenance-mode",
        },
      },
      CLIENT_CONTACTS_PORTAL: "/admin-settings/client-contacts-portal",
      CUSTOMREPORTS: "/custom-reports",
      COMPANIES: "/admin-settings/companies",
      JOBS: "/admin-settings/jobs",
      INTEGRATIONS: "/admin-settings/integrations",
      IMPORT: "/admin-settings/import",
      ONBOARDING: "/admin-settings/onboarding",
      REPORTS: "/admin-settings/reports",
    },
  },
  CANDIDATES: {
    BASE: "/candidates",
    ID: "/candidates/:candidateId",
    UPDATE: "/candidates/update/:candidateId",
    PROFILE: {
      NOTES: "/candidates/:candidateId/notes",
      DOCUMENTS: "/candidates/:candidateId/documents",
      PLACEMENTS: "/candidates/:candidateId/placements",
      RESUME: "/candidates/:candidateId/resume",
      AVAILABILITY: "/candidates/:candidateId/availability",
      PREFERENCES: "/candidates/:candidateId/preferences",
    },
  },
  ONBOARDING: {
    BASE: "/onboarding",
  },
  CONTACTS: {
    BASE: "/contacts",
    ID: "/contacts/:contactId",
    UPDATE: "/contacts/:contactId/update",
    PROFILE: {
      NOTES: "/contacts/:contactId/notes",
      LOCATIONS: "/contacts/:contactId/locations",
      SETTINGS: "/contacts/:contactId/settings",
    },
  },
  COMPANIES: {
    BASE: "/companies",
    ID: "/company/:companyId",
    UPDATE: "/company/update/:companyId",
    PROFILE: {
      NOTES: "/company/:companyId/notes",
      CONTACTS: "/company/:companyId/contacts",
      LOCATIONS: "/company/:companyId/locations",
      KIOSKS: "/company/:companyId/kiosks",
      POSITION: "/v2/api/company/:companyId/position",
    },
  },
  PLACEMENTS: {
    BASE: "/placements",
  },
  ORDERS: {
    BASE: "/orders",
  },
  MESSAGES: {
    BASE: "/messages",
  },
  PUBLIC: {
    JOBS: "/:company_id/jobs",
  },
  MESSAGING: {
    BASE: "/messaging",
    PUSH_NOTIFICATION: "/push-notification",
  },
  IFRAMES: {
    PLACEMENTS: "/iframes/placements/:company_id",
  },
};
