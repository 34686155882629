import { AxiosError } from "axios";
import { ENDPOINTS } from "@/constants/endpoints";
import { axios } from "@/utils/axios";
import { addParamValue } from "../../utils/addParamValue";

/**
 *
 * @param {string} companyId
 * @returns
 */
export const getSettings = async (companyId) => {
  try {
    const endpoint = addParamValue(ENDPOINTS.SETTINGS, {
      company_id: companyId,
    });

    const response = await axios.get(endpoint);

    if (!response.data?.data) throw new Error("Can't client portal settings");

    return response.data.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      console.error(error.response.data.message ?? error.message);
    }

    console.error(error);
  }
};

/**
 *
 * @param {string} companyId
 * @returns
 */
export const getMaintenanceModeSettings = async (companyId) => {
  try {
    const endpoint = addParamValue(ENDPOINTS.SETTINGS+'/maintenance-mode', {
      company_id: companyId,
    });

    const response = await axios.get(endpoint);

    if (!response.data?.data) throw new Error("Can't get maintenance mode settings");

    return response.data.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      console.error(error.response.data.message ?? error.message);
    }

    console.error(error);
  }
};
